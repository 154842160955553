<mat-form-field color="primary" appearance="fill">
  <mat-label>{{ title }}</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input #dateRangeStart [value]="dateFrom || ''" matStartDate placeholder="Start date" />
    <input
      #dateRangeEnd
      [value]="dateTo || ''"
      datePickerFormat="MMMM Do YYYY"
      (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"
      matEndDate
      placeholder="End date"
    />
  </mat-date-range-input>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
