import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '@wakanda/wakanda-core';
import { Observable } from 'rxjs';
import { ZenAuthConfig } from '../configs/zenauth.config';
import {
  authenticate,
  loginApple,
  loginFacebook,
  loginGoogle,
  loginMobimoney,
  loginNedid,
  loginOtp,
  loginOtpResend,
  loginProfile,
  loginRefreshToken,
  passwordlessStart,
  start,
  startSocial,
} from '../endpoints/zenAuth.endpoint';
import {
  IAuthOTPRequest,
  IAuthOTPResendRequest,
  IAuthPasswordlessLoginStartRequest,
  IAuthProfileRequest,
  IAuthRefreshTokenStartRequest,
  IAuthSocialLoginStartRequest,
  IZenAuthAutenticateRequest,
  IZenAuthOnboardingStartSocialRequest,
  IZenAuthPasswordToken,
  IZenAuthResendSmsOtpToken,
  IZenAuthSmsOtpToken,
  IZenAuthStartRequest,
  IZenAuthUsernamePasswordToken,
  IZenAuthUsernameToken,
} from '../models/requests.model';
import {
  IAuthNextStep,
  IZenAuthAuthenticateResponse,
  IZenAuthOnboardingNextStepResponse,
} from '../models/responses.model';
import { ZEN_AUTH_CONFIG } from '../tokens/app.token';
import { generateStateToken } from '../utils/common.util';
import { ApiService } from './api.service';

type IAuthToken =
  | IZenAuthUsernamePasswordToken
  | IZenAuthUsernameToken
  | IZenAuthPasswordToken
  | IZenAuthSmsOtpToken
  | IZenAuthResendSmsOtpToken;

@Injectable()
export class AuthService {
  constructor(
    private api: ApiService,
    @Inject(ZEN_AUTH_CONFIG) private config: ZenAuthConfig,
    private appConfig: AppConfig,
  ) {}

  start(): Observable<IZenAuthAuthenticateResponse> {
    const requestStart: IZenAuthStartRequest = {
      clientId: this.config.clientId,
      responseType: this.config.responseType,
      scope: this.config.scopes,
      state: generateStateToken(),
    };
    return this.api.post<IZenAuthStartRequest, IZenAuthAuthenticateResponse>(
      start(this.appConfig.backendConfig.authUrl),
      requestStart,
    );
  }

  startSocial(request: IZenAuthOnboardingStartSocialRequest): Observable<IZenAuthOnboardingNextStepResponse> {
    return this.api.post<IZenAuthOnboardingStartSocialRequest, IZenAuthOnboardingNextStepResponse>(
      startSocial(this.appConfig.backendConfig.apiUrl),
      {
        clientId: this.config.clientId,
        ...request,
      },
    );
  }

  authenticate(authToken: IAuthToken, temporaryAccessToken: string): Observable<IZenAuthAuthenticateResponse> {
    const authenticateRequest: IZenAuthAutenticateRequest = {
      authToken,
      temporaryAccessToken,
    };
    return this.api.post<IZenAuthAutenticateRequest, IZenAuthAuthenticateResponse>(
      authenticate(this.appConfig.backendConfig.authUrl),
      authenticateRequest,
    );
  }

  loginMobimoney(): Observable<IZenAuthOnboardingNextStepResponse> {
    return this.api.post(loginMobimoney(this.appConfig.backendConfig.apiUrl), { clientId: this.config.clientId });
  }

  loginNedid(): Observable<IZenAuthOnboardingNextStepResponse> {
    return this.api.post(loginNedid(this.appConfig.backendConfig.apiUrl), { clientId: this.config.clientId });
  }

  //v3
  passwordlessStart(payload: IAuthPasswordlessLoginStartRequest): Observable<IAuthNextStep> {
    return this.api.post<IAuthPasswordlessLoginStartRequest, IAuthNextStep>(
      passwordlessStart(this.appConfig.backendConfig.apiV3Url),
      payload,
    );
  }

  loginOtp(payload: IAuthOTPRequest): Observable<IAuthNextStep> {
    return this.api.post<IAuthOTPRequest, IAuthNextStep>(loginOtp(this.appConfig.backendConfig.apiV3Url), payload);
  }

  loginOtpResend(payload: IAuthOTPResendRequest): Observable<IAuthNextStep> {
    return this.api.post<IAuthOTPResendRequest, IAuthNextStep>(
      loginOtpResend(this.appConfig.backendConfig.apiV3Url),
      payload,
    );
  }

  loginRefreshToken(payload: IAuthRefreshTokenStartRequest): Observable<IAuthNextStep> {
    return this.api.post<IAuthRefreshTokenStartRequest, IAuthNextStep>(
      loginRefreshToken(this.appConfig.backendConfig.apiV3Url),
      payload,
    );
  }

  loginProfile(payload: IAuthProfileRequest): Observable<IAuthNextStep> {
    return this.api.post<IAuthProfileRequest, IAuthNextStep>(
      loginProfile(this.appConfig.backendConfig.apiV3Url),
      payload,
    );
  }

  loginGoogle(payload: IAuthSocialLoginStartRequest): Observable<IAuthNextStep> {
    return this.api.post<IAuthSocialLoginStartRequest, IAuthNextStep>(
      loginGoogle(this.appConfig.backendConfig.apiV3Url),
      payload,
    );
  }

  loginFacebook(payload: IAuthSocialLoginStartRequest): Observable<IAuthNextStep> {
    return this.api.post<IAuthSocialLoginStartRequest, IAuthNextStep>(
      loginFacebook(this.appConfig.backendConfig.apiV3Url),
      payload,
    );
  }

  loginApple(payload: IAuthSocialLoginStartRequest): Observable<IAuthNextStep> {
    return this.api.post<IAuthSocialLoginStartRequest, IAuthNextStep>(
      loginApple(this.appConfig.backendConfig.apiV3Url),
      payload,
    );
  }
}
