import { IAmount, WeekDays } from './common.model';
import { IHomepageWalletDetail } from './dashboard.model';
import { IPosition } from './location.model';
import { IShoppingAvailablePaymentMethod } from './shopping.model';
import { IBuyer } from './buyer.model';

export interface IWalletPaymentRequest {
  amount: IAmount;
  description?: string;
  phoneNumber?: string;
  paymentMode?: string; // todo
  recipientWalletId?: string;
}

export interface IWalletPayResponse {
  transactionId: string;
  date: string;
  paymentMode: WalletPaymentMode;
}

export interface IWalletPaymentRequestResponse {
  transactionId: string;
  date: string;
  paymentMode: WalletPaymentMode;
}

export interface IWalletPhoneNumberRequest {
  phoneNumber: string;
}

export interface IWalletPhoneNumberResponse {
  phoneNumber: string;
  isWakanda: boolean;
}

export enum MerchantTypes {
  B2B = 'B2B',
  B2C = 'B2C',
}

export interface IWalletExternalAccount {
  externalAccountId: string;
  name: string;
  accountNumber: string;
  type: WalletExternalAccountType;
  icon: string;
  primary: boolean;
  bank: string;
  branch: string;
  holderName: string;
  reference: string;
  accountInfo: WalletExternalAccountInfo;
}

export interface IWalletSettlementAccountRequest {
  acceptFeeDeduction: boolean;
  accountHolder: string;
  accountNumber: string;
  accountType: WalletExternalAccountInfo;
  bankName: string;
  branchCode: string;
  confirmProvidedInformationIsAccurate: boolean;
  recipientReference?: string;
  id?: number;
  isDebit?: boolean;
  isSettlement?: boolean;
  saId?: string;
}

export interface IWalletSettlementAccountTypeRequest {
  isDebit: boolean;
  isSettlement: boolean;
}

export interface IWalletSettlementAccount {
  accountHolder: string;
  accountNumber: string;
  accountType: WalletExternalAccountInfo;
  bankName: string;
  branchCode: string;
  id?: number;
  recipientReference?: string;
  isDebit?: boolean;
  isSettlement?: boolean;
}

export interface IWalletCreditLoanTypeResponse {
  type: CreditLoanType;
}

export enum CreditLoanType {
  GHOST = 'GHOST',
  NTP = 'NTP',
}

export interface ISettlementExport {
  taskId?: string;
}

export interface ITransactionExport {
  taskId?: string;
}

export interface ITaskDetail {
  log?: string;
  name?: string;
  outputFileId?: string;
  outputFileUrl?: string;
  state?: TaskState;
  submittedBy?: string;
  taskId?: string;
}

export enum TaskState {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  FAILED = 'FAILED',
}

export interface IFilterSettlementRequest {
  completed?: boolean;
  createdFrom?: string;
  createdTo?: string;
  fulfilmentId?: string;
  settlementId?: string;
}

export interface IFilterPayoutTransactionRequest {
  createdFrom?: string;
  createdTo?: string;
  fulfilmentId?: string;
  restaurantBranchId?: number;
  restaurantId?: number | string;
  settled?: boolean;
  settlementId?: string;
  storeId?: string;
  walletTransactionId?: string;
  businessMarketType?: BusinessMarketType;
  excludeSubscriptions?: boolean;
}

export interface SellerPayoutTransactions {
  items: SellerPayoutTransactionItem[];
  page: number;
  totalItems: number;
  totalPages: number;
}

export interface SellerPayoutTransactionItem {
  id: number;
  amount: number;
  currency: string;
  description: string;
  settledIn: SellerSettlementDto;
  orderNumber: string;
  fulfilmentId: number;
  storeName: string;
  storeId: string;
  premiseId: string;
  premiseName: string;
  partOfRefund: boolean;
  feeType: SellerPayoutFeeType;
  businessMarketType: BusinessMarketType;
}

export enum BusinessMarketType {
  B2B = 'B2B',
  B2C = 'B2C',
}

export interface FilterSettlements {
  items: FilterSettlementItem[];
  page: number;
  totalItems: number;
  totalPages: number;
}

export interface FilterSettlementItem {
  accountHolder: string;
  accountNumber: string;
  accountType: WalletExternalAccountInfo;
  amount: number;
  bankName: string;
  branchCode: string;
  channel: WalletSettlementChannel;
  completed: boolean;
  completedAt: string;
  currency: string;
  description: string;
  destinationWalletId: number;
  id: number;
  recipientReference: string;
  walletTransactionId: string;
}

export enum SellerPayoutFeeType {
  ESCROW = 'ESCROW',
  SUCCESS_FEE = 'SUCCESS_FEE',
  MARKUP = 'MARKUP',
  TRANSACTION_FEE = 'TRANSACTION_FEE',
  PICKUP_FEE = 'PICKUP_FEE',
  SHIPPING_FEE = 'SHIPPING_FEE',
  DELIVERY_INSURANCE_FEE = 'DELIVERY_INSURANCE_FEE',
}

export interface IAddCardResponse {
  actionPlan: [];
  createdAt: string;
  currency: string;
  customerId: string;
  earnedPoints: number;
  externalUniqueId: string;
  flowId: string;
  lastUpdatedAt: string;

  createdCustomerPaymentMethod?: ICustomerStoreOfValue;
}

export interface IPayCardResponse {
  completionUrl: string;
}

export enum AddCardCallback {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  TRY_AGAIN = 'TRY_AGAIN',
  ERROR = 'ERROR',
}

export enum CardFailureReason {
  USER_CANCELED = 'USER_CANCELED',
  CANCELED = 'CANCELED',
  DECLINED = 'DECLINED',
  UNKNOWN = 'UNKNOWN',
}
export interface CardCallbackText {
  title: string;
  message: string;
  confirm: string;
}
export interface SellerSettlementDto {
  accountHolder: string;
  accountNumber: string;
  accountType: WalletExternalAccountInfo;
  amount: number;
  bankName: string;
  branchCode: string;
  channel: WalletSettlementChannel;
  completed: boolean;
  completedAt: string;
  currency: string;
  description: string;
  destinationWalletId: number;
  id: number;
  recipientReference: string;
  walletTransactionId: string;
}

// TaxInvoices
export interface ITaxInvoiceRequest {
  year?: number;
  dateFrom?: string;
  dateTo?: string;
  invoiceNumber?: string;
}

// Payment remittance
export interface IGenerateRemittanceRequest {
  generatedFrom?: string;
  generatedTo?: string;
  documentType?: ISellerGenerateStatementDocumentType;
}

export interface ITaxInvoice {
  items: ISellerTaxInvoiceDto[];
  page: number;
  totalItems: number;
  totalPages: number;
}

export interface ISellerTaxInvoiceDto {
  id: number;
  state: TaxInvoiceState;
  invoiceNumber: string;
  month: number;
  year: number;
  createdAt: string;
}

export enum TaxInvoiceState {
  PROCESSING = 'PROCESSING',
  GENERATED = 'GENERATED',
  EMPTY = 'EMPTY',
  SENT = 'SENT',
  NOT_SENT = 'NOT_SENT',
}

export interface IWalletPartyAccount {
  accountNumber: string;
  accountName: string;
  accountType: string;
}

export interface IWalletTopUpCardResponse {
  cardId: string;
  token: string;
  cardName: string;
  cardBrand: string;
  cardExpiry: string;
  cardLast4: string;
}

export interface IWalletTopUpAddCardRequest {
  redirectSuccessUrl?: string;
  redirectFailUrl?: string;
  redirectTryAgainUrl?: string;
  redirectErrorUrl?: string;
}

export interface IWalletTopUpPayRequest extends IWalletTopUpAddCardRequest {
  amount: IAmount;
}

export interface IWalletTopUpPay extends IWalletTopUpPayRequest {
  cardId: string;
}

export interface IWalletTopupRequest {
  externalAccountId: string;
  amount: IAmount;
  TxSign: string;
}

export interface IWalletTopupResponse {
  sourceAccountId: string;
  amount: IAmount;
  transactionId: string;
  accountNumber: string;
  settlementDate: string;
}

export interface IWalletAtm {
  atmName: string;
  atmLocation: IPosition;
}

export interface IWalletWithdrawResponse {
  atmSecretCode: boolean;
  atmSecretCodeExpiration: string;
  transactionId: string;
  accountNumber?: string;
  settlementDate: string;
  voucherName?: string;
  voucherAmount?: IAmount;
}

export interface IWalletWithdrawRequest {
  amount: IAmount;
}

export interface IWalletSettlementRequest {
  accountId: number;
  amount: IAmount;
}

export interface IWalletSettlementResponse {
  transactionId: string;
  settlementDate: string;
  accountNumber: string;
  amount: IAmount;
}

export enum WalletExternalAccountType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum WalletExternalAccountInfo {
  SAVINGS = 'SAVINGS',
  CHEQUE = 'CHEQUE',
}

export interface IWalletRecentRecipient {
  phoneNumber: string;
  isWakanda: boolean;
}

export interface IWalletLimit {
  limit: IAmount;
  level: IAmount;
}

export interface IWalletTransaction {
  id: string;
  jobId?: string;
  icon: string;
  name: string;
  amount: IAmount;
  date: string;
  direction: WalletTransactionDirection;
  type: WalletTransactionType;
  paymentMode: WalletPaymentMode;
  status?: string;
}

export interface IWalletTransactionForTable {
  id: string;
  amount: string;
  name: string;
  date: string;
  status: string;
}

export enum WalletTransactionDirection {
  PENDING = 'PENDING',
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
  ALL = 'ALL',
}

export interface IWalletSettlement {
  createdSettlement: IWalletSettlementResponse;
}

export interface ISellerStatementDto {
  state: ISellerGeneratedStatementState;
  createdAt: string;
  generatedFrom: string;
  generatedTo: string;
  merchantId: string;
  storeId: string;
  statementNumber: string;
  v1Statement: boolean;
  pdfFile: IAsset;
  csvFile: IAsset;
}

export interface IAsset {
  fileId: string;
  url: string;
}

export enum ISellerGeneratedStatementState {
  NEW = 'NEW',
  GENERATING = 'GENERATING',
  GENERATED = 'GENERATED',
  GENERATE_ERROR = 'GENERATE_ERROR',
  SENT = 'SENT',
  SEND_ERROR = 'SEND_ERROR',
  PREVIEW_GENERATING = 'PREVIEW_GENERATING',
  PREVIEW_GENERATED = 'PREVIEW_GENERATED',
}

export interface ISellerStoreStatementDto {
  storeId: string;
  storeName: string;
  purchaseAmount: number;
  debit: number;
  credit: number;
  total: number;
  storeStatement: boolean;
}

export interface ISellerStatementRequest {
  generatedFrom?: string;
  generatedTo?: string;
}

export interface ISellerStatementExportRequest {
  generatedFrom?: string;
  generatedTo?: string;
  documentType?: ISellerGenerateStatementDocumentType;
}

export enum ISellerGenerateStatementDocumentType {
  CSV = 'CSV',
  PDF = 'PDF',
  XLSX = 'XLSX',
}

export interface ISellerStatementExport {
  taskId: string;
}

export interface IPaymentRemittanceExport {
  taskId: string;
}

export interface ISellerStatement {
  totalItems: number;
  totalPages: number;
  page: number;
  items: ISellerStatementDto[];
}

export interface ILegacyWallet {
  walletId: string;
  balance: number;
}

export interface IMyMoneyWalletState {
  wallets?: IWallet[];
  wallet?: ILegacyWallet;
  transactions?: IWalletTransaction[];
  filter?: IWalletStatementFilter;
  recentTransactions?: IWalletRecentSearch[];
  transactionDetail?: IWalletTransactionDetail;
  accept?: IWalletAccept;
  qrCodePayData?: IQrCodeDecoded;
  qrCodePayDataString?: string;
  paymentLink?: string;
  payRecentRecipient?: IWalletPayRecentRecipient[];
  card?: IWalletCardResponse;
  addCard?: IWalletAddCardResponse;
  externalAccounts?: IWalletExternalAccount[];
  savedExternalAccount?: IWalletExternalAccount;
  settlement?: IWalletSettlement;
  banks?: IWalletBank[];
  nedbankBanks?: IWalletBankInfo[];
  bankAccount?: IWalletSettlementAccount[];
  addedBankAccount?: IWalletSettlementAccount;
  settlementSettings?: IWalletSettlementSettings;
  filteredBanks?: IWalletBank[];
  cardTopup?: IWalletTopUpCardResponse[];
  selectedTopUpCard?: IWalletTopUpCardResponse;
  payTopUp?: IWalletTopUpPayRequest;
  paymentLinkResponse?: IWalletPaymentLinkResponse;
  withdrawResponse?: IWalletWithdrawResponse;
  loyaltyPoints?: ILoyaltyPoint;
  payCheckResponse?: IWalletCheckPayResponse;
  topUpAccounts?: IWalletTopupResponse;
  partyAccounts?: IWalletPartyAccount[];
  activeBuyer?: IBuyer;
  activePaymentMethods?: ICustomerStoreOfValue;
  subscriptions?: IWalletSubscription[];
  availableSubscriptions?: IWalletSubscriptionAvailable[];
  addedSubscription?: IWalletSubscription;
  dashboardWidget?: IDashboardWalletWidget;
  paymentMethod?: IShoppingAvailablePaymentMethod;
  sellerPayoutTransactions?: SellerPayoutTransactions;
  taskDetail?: ITaskDetail;
  paymentInfo?: IPaymentInfo;
  taxInvoices?: ITaxInvoice;
  hasBuyerStandardSubscription?: boolean;
  sellerStatements?: ISellerStatement;
  paymentCarousel?: ICustomerStoreOfValue[];
  buyerTransactions?: IPaymentMethodTransaction[];
  transactionFilter?: ITransactionHistoryReportFilter;
  selectedStoreOfValue?: ICustomerStoreOfValue;
}

export interface IWalletTransactionDetail {
  id: string;
  title: string;
  amount: IAmount;
  refund?: IWalletTransactionRefundDetail;
  date: string;
  dateReceived?: string;
  reference?: string;
  type: WalletTransactionType;
  paymentMode?: WalletPaymentMode;
  buttons: IWalletTransactionDetailButton[];
  jobId?: string;
  atmAddress?: string;
  withdrawalAccountName?: string;
  withdrawalAccountNumber?: string;
  metadata?: Map<string, string>;
}

export interface IWalletTransactionRefundDetail {
  id: string;
  amount: IAmount;
  dateRefunded: string;
}

// todo(lukas.jurygacek): move similar facade methods from customer to core
export interface IDashboardWalletWidget {
  createWallet: boolean;
  summaryBold?: string;
  summaryText?: string;
  wallets?: IHomepageWalletDetail[];
  avoBalance?: IAmount;
  avoZarRate?: number;
}

export interface IWalletReminder {
  message: string;
}

export interface IWalletRefund {
  amount: IAmount;
}

export interface IWalletRecentSearch {
  id: string;
  searchQuery?: string;
  searchString?: string;
}

export interface IWalletRefundResponse {
  label: string;
  value: IAmount;
}
export interface ICustomerStoreOfValue {
  id: string;
  name: string;
  defaultMethod: boolean;
  canBeRemoved?: boolean;
  canBeSetAsDefault?: boolean;
  customerStoreOfValueType: CustomerStoreOfValueType;
  paymentCardDetails?: IPaymentCardDetails;
  transactionalProductDetails?: ITransactionalProductDetails;
  loyaltyAndRewardsDetails?: ILoyaltyAndRewardsDetails;
  digitalWalletDetails?: IDigitalWalletDetails;
}

export interface IPayCardDpoRequest {
  redirectSuccessUrl: string;
  redirectFailUrl: string;
  redirectTryAgainUrl: string;
  redirectErrorUrl: string;
}

export interface ITransactionalProductDetails {
  accountNumber: string;
  bankBranchNote: string;
  bankName: string;
  iban: string;
  bic: string;
}

export interface IPaymentCardDetails {
  cardPaymentProvider: any;
  expirationYear: string;
  expirationMonth: string;
  last4Digits: string;
  first6Digits: string;
  formattedMaskedNumber: string;
  expired: true;
  cardIsBlocked: true;
}

export interface IWalletStatementFilter {
  dateFrom?: string;
  dateTo?: string;
  received?: boolean;
  paid?: boolean;
  withdrawn?: boolean;
  refunds?: boolean;
  pending?: boolean;
  receivedFrom?: string;
  sentTo?: string;
  location?: string;
  added?: string;
  settled?: string;
  format?: WalletStatementFormat;
}

export interface IDigitalWalletDetails {
  walletId: string;
  sovWalletId: string;
  digitalWalletType: string;
}

export interface ILocalCurrencyBalance {
  formatted: string;
  amount: 0;
  currencyCode: string;
}
export interface ILoyaltyAndRewardsDetails {
  localCurrencyBalance: ILocalCurrencyBalance;
  walletId: string;
  eclipseWalletId: string;
  countryCode: any;
  fundsExpirationDate: any;
  balance: Ibalance;
}

export interface Ibalance {
  formatted: string;
  amount: 0;
  currencyCode: string;
}
export enum WalletStatementFormat {
  PDF = 'PDF',
  CSV = 'CSV',
}

export enum WalletTransactionType {
  RECEIVED_FROM = 'RECEIVED_FROM',
  PAID_TO = 'PAID_TO',
  REQUESTED_FROM = 'REQUESTED_FROM',
  REQUESTED_QR = 'REQUESTED_QR',
  TOP_UP = 'TOP_UP',
  WITHDRAWAL_ATM = 'WITHDRAWAL_ATM',
  WITHDRAWAL_ACCOUNT = 'WITHDRAWAL_ACCOUNT',
  REFUND = 'REFUND',
  OTHER = 'OTHER',
}

export enum WalletPaymentMode {
  WALLET_TRANSFER = 'WALLET_TRANSFER',
  PAYMENT_LINK = 'PAYMENT_LINK',
  QR_CODE = 'QR_CODE',
}

export interface IWalletTransactionDetailButton {
  label: string;
  amount: IAmount;
  type: WalletTransactionDetailButtonType;
}

export enum WalletTransactionDetailButtonType {
  PAY_AGAIN = 'PAY_AGAIN',
  VIEW_JOB = 'VIEW_JOB',
  REFUND = 'REFUND',
  SEND_REMINDER = 'SEND_REMINDER',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  REPEAT_TOPUP = 'REPEAT_TOPUP',
  REPEAT_WITHDRAWAL = 'REPEAT_WITHDRAWAL',
  VIEW_STATEMENT = 'VIEW_STATEMENT',
}

export interface IWallet {
  walletId: string;
  id: string;
  type: WalletType;
  availableBalance: IAmount;
  transactionDailyLimit: IAmount;
  userName: string;
  QRCode: string;
  walletAccountName: string;
  walletAccountNumber: string;
  walletAccountBank: string;
  walletAccountBranch: string;
  walletAccountReference: string;
  atmWithdrawNumber?: string;
  reservedEscrow: IAmount;
  main: boolean;
  canBeMain: boolean;
  lastUsed: boolean;
  sovType: WalletSovType;
  ownerId: string;
  ownerType: WalletOwnerType;
  walletName?: string;
  accountForLinkingAvailable?: boolean;
}

export enum WalletSovType {
  WALLET = 'WALLET',
  TP = 'TP',
}

export enum WalletOwnerType {
  CUSTOMER = 'CUSTOMER',
  MERCHANT = 'MERCHANT',
}

export interface IWalletBank {
  code: string;
  name: string;
  branches: IWalletBranch[];
  hasUniversalBranch?: boolean;
}

export interface IWalletBranch {
  code: string;
  name: string;
}

export interface IWalletBankInfo {
  bankCode: number;
  bankName: string;
  universalBranchCode: string;
}

export enum WalletType {
  MAIN = 'MAIN',
  SUB = 'SUB',
  CHILD = 'CHILD',
}

export interface IGroupedWalletTransactions {
  title: string;
  transactions: IWalletTransaction[];
}

export interface IWalletAccept {
  activeComponentName: string;
  qr?: IWalletQr;
  qrCode?: string;
}

export interface IWalletQr {
  amount?: number;
  currencyCode?: string;
  username?: string;
}

export interface IWalletQrRequest {
  amount: IWalletQr;
}

export interface IQrCodeDecoded {
  qrCodeType: QrCodeType;
  walletId?: string;
  recipientName?: string;
  amount?: IAmount;
  amountEditable?: boolean;
  description?: string;
  merchantIdentifier?: string;
  merchantName?: string;
  transactionReference?: string;
  requestTip?: boolean;
  extraInfoFields?: IQrExtraInfoField[];
}

export interface IWalletRenameRequest {
  walletName: string;
}

export enum QrCodeType {
  AVO = 'AVO',
  MASTERPASS = 'MASTERPASS',
  SNAPSCAN = 'SNAPSCAN',
  ZAPPER = 'ZAPPER',
}

export interface IQrExtraInfoField {
  id: string;
  title: string;
  dataType: string;
  defaultValue?: IAmount;
  regex?: string;
  regexFailureText?: string;
  value?: string;
}

export interface IWalletQrCodePaymentRequest {
  amount: IAmount;
  tipAmount?: IAmount;
  qrCode: string;
  extraInfoFields?: IQrExtraInfoField[];
  description?: string;
}

export interface IWalletRequest {
  amount: IAmount;
  description?: string;
  reference?: string;
  phoneNumber: string;
  recipientId?: string;
}

export interface IWalletRequestResponse {
  transactionId: string;
  date: string;
  paymentMode: WalletPaymentMode;
}

export interface IWalletPayRecentRecipient {
  phoneNumber: string;
  isWakanda: boolean;
  userId?: string;
  walletId?: string;
}

export interface IWalletCardResponse {
  id: string;
  cardBand: string;
  expiry: string;
  cardLast4: string;
}

export interface IWalletAddCardResponse {
  cardId: string;
  cardName: string;
  completionUrl: string;
  topupId: string;
}

export interface IWalletAddCardStatusResponse {
  topupId: string;
  cardId: string;
  status: WalletTopUpStatus;
  errorCode?: string;
  timeout: boolean;
}

export interface IWalletEditCardRequest {
  cardName: string;
}

export interface IWalletPaymentLinkResponse {
  id: string;
  recipientPhone: string;
  senderName: string;
  senderPhone: string;
  senderPhoto: string;
  created: string;
  expiresAt: string;
  amount: IAmount;
  type: IWalletPaymentLinkType;
  description?: string;
  referenceData?: string;
}

export interface ILoyaltyPoint {
  points: number;
  pointsRate: number;
  reservedPointsEscrow: number;
}

export enum IWalletPaymentLinkType {
  PAYMENT = 'PAYMENT',
  PAYMENT_REQUEST = 'PAYMENT_REQUEST',
}

export interface IWalletCheckPayRequest {
  purchaseType: WalletPurchaseType;
  amount: IAmount;
  allowPoints: boolean;
}

export enum WalletPurchaseType {
  VAS = 'VAS',
  OTHER = 'OTHER',
  P2P = 'P2P',
}

export interface ITopupWidgetResult {
  sourceId: string;
  sourceType: WalletTopupType;
  amount: IAmount;
}

export interface IWalletCheckPayResponse {
  checkPayStatus: WaletCheckPayResponseType;
  exceededLimitType?: WalletExceededLimitType;
  limit?: IWalletLimit;
  topUpAccounts?: IWalletTopupResponse[];
  topUpCards?: IWalletTopUpCardResponse[];
  topUpAmountMinimum?: IAmount;
  topUpAmountMaximumCard?: IAmount;
  topUpAmountMaximumAccount?: IAmount;
  topUpAmountRecommended?: IAmount;
  minimumPoints?: number;
}

export interface IWalletTopUpAccountRequest {
  accountNumber: string;
  accountBank: string;
  amount: IAmount;
}

export interface IWalletTopUpAccountResponse {
  topupId: string;
}

export interface IWalletTopUpStatusResponse {
  topupId: string;
  status: WalletTopUpStatus;
  errorCode?: string;
  timeout?: boolean;
}

export interface IWalletSubscriptionAvailable {
  id: string;
  provider: WalletSubscriptionProvider;
  providerName: string;
  type: WalletSubscriptionType;
  subscriptionName: string;
  subscriptionDescription: string;
  subscriptionDetails: string[];
  price: IAmount;
}

export interface IWalletSubscription extends IWalletSubscriptionAvailable {
  active: boolean;
  renewEnabled: boolean;
  nextPaymentDate?: string;
  trial?: boolean;
  canBeDeactivated?: boolean;
  checkPayResponse?: IWalletCheckPayResponse;
}

export interface IWalletSubscriptionRequest {
  id: string;
}

export enum SubscriptionConfirmType {
  NEW = 'NEW',
  ACTIVATE_EXISTING = 'ACTIVATE_EXISTING',
}

export enum WalletCardTopupCallbackUrlType {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  ERROR = 'ERROR',
  TRY_AGAIN = 'TRY_AGAIN',
}

export enum WalletCardPaymentCallbackUrlType {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  ERROR = 'ERROR',
  TRY_AGAIN = 'TRY_AGAIN',
}

export enum WaletCheckPayResponseType {
  ENOUGH_BALANCE = 'ENOUGH_BALANCE',
  LIMIT_EXCEEDED = 'LIMIT_EXCEEDED',
  TOP_UP_NEEDED = 'TOP_UP_NEEDED',
  USE_POINTS = 'USE_POINTS',
}

export enum WalletTopupType {
  ACCOUNT = 'ACCOUNT',
  CARD = 'CARD',
}

export enum WalletExceededLimitType {
  MAX_BALANCE = 'MAX_BALANCE',
  SINGLE_TRANSACTION = 'SINGLE_TRANSACTION',
  VAS_DAILY = 'VAS_DAILY',
  VAS_MONTHLY = 'VAS_MONTHLY',
  OUTGOING_PAYMENTS_DAILY = 'OUTGOING_PAYMENTS_DAILY',
  OUTGOING_PAYMENTS_MONTHLY = 'OUTGOING_PAYMENTS_MONTHLY',
  TOPUP_DAILY = 'TOPUP_DAILY',
  TOPUP_MONTHLY = 'TOPUP_MONTHLY',
}

export enum WalletTopUpStatus {
  NOT_FOUND = 'NOT_FOUND',
  SUCCESS = 'SUCCESS',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
}

export enum WalletSubscriptionProvider {
  HEYNED = 'HEYNED',
}

export enum WalletSubscriptionType {
  MONTHLY = 'MONTHLY',
  PER_TASK = 'PER_TASK',
  ONE_TASK = 'ONE_TASK',
  B2B_BUYER_STANDARD_MONTHLY = 'B2B_BUYER_STANDARD_MONTHLY',
  B2B_BUYER_STANDARD_ANNUAL = 'B2B_BUYER_STANDARD_ANNUAL',
  B2B_BUYER_ESSENTIAL = 'B2B_BUYER_ESSENTIAL',
  B2B_SELLER_STANDARD_MONTHLY = 'B2B_SELLER_STANDARD_MONTHLY',
  B2B_SELLER_STANDARD_ANNUAL = 'B2B_SELLER_STANDARD_ANNUAL',
  B2B_SELLER_ESSENTIAL = 'B2B_SELLER_ESSENTIAL',
  B2C_SELLER_STANDARD_ANNUAL = 'B2C_SELLER_STANDARD_ANNUAL',
  B2C_SELLER_STANDARD_MONTHLY = 'B2C_SELLER_STANDARD_MONTHLY',
  B2C_ONDEMAND_MONTHLY = 'B2C_ONDEMAND_MONTHLY',
  B2C_ONDEMAND_ANNUAL = 'B2C_ONDEMAND_ANNUAL',
}

export enum WalletDashboardWidgetType {
  AVOBOARD = 'AVOBOARD',
  LOAN = 'LOAN',
  WALLET = 'WALLET',
}

export enum WalletSettlementChannel {
  WALLET = 'WALLET',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
}

export enum WalletSettlementPeriod {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export interface SettlementPeriodSettings {
  period: WalletSettlementPeriod;
  weeklySettlementDay?: WeekDays;
  monthlySettlementDay?: number;
}

export interface IWalletChangeSettlementSettingsRequest {
  channel: WalletSettlementChannel;
  walletId?: string;
  settlementAccountId?: number;
  settlementPeriod?: WalletSettlementPeriod;
  weeklySettlementDay?: WeekDays;
  monthlySettlementDay?: number;
}

export interface IWalletSettlementSettings {
  channel: WalletSettlementChannel;
  walletId?: string;
  settlementAccount?: IWalletSettlementAccount;
  settlementPeriod?: WalletSettlementPeriod;
  weeklySettlementDay?: WeekDays;
  monthlySettlementDay?: number;
}

export interface IPaymentInfo {
  amount: IAmount;
  availableMethods: IPaymentAvailablePaymentMethod[];
  paymentStatus: PaymentStatus;
  earnedPoints?: number;
}

export interface IPaymentAvailablePaymentMethod {
  defaultMethod: boolean;
  method: PaymentMethod;
  splitPaymentAllowed: boolean;
  wallets: IWalletAvailablePaymentMethod[];
  missingPoints?: number;
  cards?: ICard[];
  loanOptions?: IPaymentAvailablePaymentMethodLoan[];
}

export interface IPaymentAvailablePaymentMethodLoan {
  loanTerm: number;
  monthlyAmount: IAmount;
}

export interface IWalletAvailablePaymentMethod {
  availableBalance?: IAmount;
  checkPayStatus?: WaletCheckPayResponseType;
  defaultWallet?: boolean;
  exceededLimitType?: WalletExceededLimitType;
  limit?: IWalletLimit;
  minimumPoints?: number;
  topUpAccounts?: ITopUpAccount[];
  topUpAmountMaximumAccount?: IAmount;
  topUpAmountMaximumCard?: IAmount;
  topUpAmountMinimum?: IAmount;
  topUpAmountRecommended?: IAmount;
  topUpCards?: ICard[];
  walletId?: string;
  walletName?: string;
}

export interface IWalletLimit {
  level: IAmount;
  limit: IAmount;
}

export interface ITopUpAccount {
  accountBank?: string;
  accountNumber?: string;
  balance?: IAmount;
  nickname?: string;
}

export interface ICard {
  cardBrand?: string;
  cardExpiry?: string;
  cardId?: string;
  cardLast4?: string;
  cardName?: string;
}
export enum ManageCardType {
  NAME,
  DEFAULT_CARD,
  DEFAULT_CARD_SUCCESS,
  NAME_AND_CARD_SUCCESS,
  REMOVE_CARD,
  REMOVE_CARD_SUCCESS,
}

export interface IManageCardDialogData {
  selectedItem: ICustomerStoreOfValue;
  allItems?: ICustomerStoreOfValue[];
}

export interface IPayByCardRequest {
  redirectSuccessUrl?: string;
  redirectFailUrl?: string;
  redirectTryAgainUrl?: string;
  redirectErrorUrl?: string;
  usedPoints?: number;
  cardId?: string;
  currentUserContext?: ICurrentUserContext;
}

export interface IPayByCreditRequest {
  loanTerm: number;
}

export interface ITopUpAndPayRequest {
  redirectSuccessUrl?: string;
  redirectFailUrl?: string;
  redirectTryAgainUrl?: string;
  redirectErrorUrl?: string;
  storedCardId?: string;
  usedPoints: number;
  walletId: string;
  amount: number;
  currentUserContext?: ICurrentUserContext;
}

export interface ICurrentUserContext {
  addresses?: ICrmAddress[];
  agent?: boolean;
  anonymous?: boolean;
  appClientType?: AppClientType;
  authenticated?: boolean;
  customer?: boolean;
  customerId?: string;
  customerOrMerchant?: boolean;
  customerOrWithCustomerToken?: boolean;
  customerOrWithCustomerTokenOrMerchant?: boolean;
  dateOfBirth?: string;
  email?: string;
  firstName?: string;
  fullName?: string;
  lastName?: string;
  merchant?: boolean;
  merchantId?: string;
  middleName?: string;
  mobileNumber?: string;
  nickname?: string;
  principalName?: string;
  system?: boolean;
  tags?: string[];
  userId?: string;
  withCustomerToken?: boolean;
}

export enum AppClientType {
  CUSTOMER = 'CUSTOMER',
  MERCHANT = 'MERCHANT',
  AGENT = 'AGENT',
}

export interface ICrmAddress {
  addressString?: string;
  addressType?: CrmAddressTypeType;
  buildingBusinessName?: string;
  city?: string;
  complex?: string;
  country?: string;
  createdOn?: string;
  deliveryInstructions?: string;
  estateNo?: string;
  geocodingData?: string;
  houseNo?: string;
  lastUsedOn?: string;
  latitude?: number;
  longitude?: number;
  name?: string;
  operatingRadius?: number;
  postalCode?: string;
  stateOrProvince?: string;
  streetName?: string;
  streetNumber?: string;
  suburb?: string;
  type?: CrmAddressType;
  unitNo?: string;
}

export enum CrmAddressTypeType {
  HOME = 'Home',
  OFFICE = 'Office',
  TOWNHOUSE_COMPLEX = 'TownhouseComplex',
  ESTATE = 'Estate',
}

export enum CrmAddressType {
  BILL_TO = 'BillTo',
  SHIP_TO = 'ShipTo',
  PRIMARY = 'Primary',
  OTHER = 'Other',
  REGISTERED = 'Registered',
  TRADING = 'Trading',
  BUSINESS = 'Business',
}

export interface IPayByWalletRequest {
  usedPoints: number;
  walletId: string;
  currentUserContext?: ICurrentUserContext;
}

export interface ITopUpAndPayResponse {
  completionUrl: string;
  topupId: string;
}

export interface IPayByCardResponse {
  completionUrl: string;
  topupId: string;
}

export interface IPayByCreditResponse {
  loanOriginationId: string;
}

export enum PaymentMethod {
  WALLET = 'WALLET',
  PAYMENT_CARD = 'PAYMENT_CARD',
  EFT = 'EFT',
  FREE = 'FREE',
  POINTS_ONLY = 'POINTS_ONLY',
  AMEX = 'AMEX',
  CREDIT = 'CREDIT',
}

export enum CustomerStoreOfValueType {
  LR = 'LOYALTY_AND_REWARDS',
  PAYMENT_CARD = 'PAYMENT_CARD',
  TP = 'TP',
  NEW = 'NEW',
  DIGITAL_WALLET = 'DIGITAL_WALLET',
}

export enum CustomerStoreOfValueProvider {
  LR_AVO = 'LR_AVO',
  TP_NEDBANK_ACCOUNT = 'TP_NEDBANK_ACCOUNT',
  PAYMENT_CARD_DPO = 'PAYMENT_CARD_DPO',
  PAYMENT_CARD_IVERI = 'PAYMENT_CARD_IVERI',
  UNKNOWN = 'UNKNOWN',
}

export enum PaymentCardProvider {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMEX = 'AMEX',
}
export enum PaymentStatus {
  NOT_PAID = 'NOT_PAID',
  IN_PROGRESS = 'IN_PROGRESS',
  PAID = 'PAID',
}

export interface IMyProfileBusinessAddress {
  houseNumber: string;
  streetName: string;
  suburb: string;
  city: string;
  postalCode: string;
  country?: string;
  countryCode?: string;
}

export interface ICompanyCheckExistsResponse {
  nameExists: boolean;
}

export const MY_MONEY_WALLET_FEATURE_KEY = 'myMoneyWallet';

export interface ITransactionDetail {
  occuredAt: string;
  orderNumber: string;
  amount: number;
  currencyCode?: string;
  orderVertical: string;
  transactionDirection: TransactionDirection;
  titleType: string;
  type: TransactionDetailType;
  transactionReference: string;
  vendorId: string;
  vendorNames: string[];
  customerPaymentMethodId: string;
  customerPaymentMethodNickname: string;
  storeOfValueType: CustomerStoreOfValueType;
  storeOfValueProvider: string;
  orderType: string;
  exchangeFromAmount?: number;
  exchangeFromLoyaltyProgram?: string;
  loyaltyAndRewardsTransactionId?: string;
}

export enum TransactionDirection {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export enum TransactionDetailType {
  PURCHASE = 'PURCHASE',
  REFUND = 'REFUND',
  EXCHANGE = 'EXCHANGE',
  REWARD = 'REWARD',
  REWARD_REVERSAL = 'REWARD_REVERSAL',
  UNKNOWN = 'UNKNOWN',
}

export interface ITransactionHistoryReportFilter {
  createdFrom?: string;
  createdTo?: string;
  amountFrom?: number;
  amountTo?: number;
  paymentMethodIds?: string[];
}

export interface IEditPaymentMethodRequest {
  newName?: string;
  defaultMethod?: boolean;
}

export interface IChangeDefaultCardDialog {
  defaultCardName: string;
  newCardName: string;
}

export interface IPaymentMethodTransaction {
  paymentMethodId: string;
  transactionDetails: ITransactionDetail[];
}

export interface ITransactionListDialogData {
  selectedWalletId?: string;
  wallets?: ICustomerStoreOfValue[];
}
