import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  IAddCardResponse,
  Ibalance,
  IEditPaymentMethodRequest,
  IFilterPayoutTransactionRequest,
  IFilterSettlementRequest,
  IGenerateRemittanceRequest,
  IMyMoneyWalletState,
  IPayByCardRequest,
  IPayByCardResponse,
  IPayByCreditRequest,
  IPayByCreditResponse,
  IPayByWalletRequest,
  IPaymentRemittanceExport,
  ISellerStatementExport,
  ISellerStatementExportRequest,
  ISellerStatementRequest,
  ISettlementExport,
  ITaskDetail,
  ITaxInvoice,
  ITaxInvoiceRequest,
  ITopUpAndPayRequest,
  ITopUpAndPayResponse,
  IWallet,
  IWalletAddCardResponse,
  IWalletCheckPayRequest,
  IWalletExternalAccount,
  IWalletPartyAccount,
  IWalletPayResponse,
  IWalletTopUpAccountRequest,
  IWalletTopUpAccountResponse,
  IWalletTopUpAddCardRequest,
  IWalletTopUpCardResponse,
  IWalletTopUpPayRequest,
  IWalletTopUpStatusResponse,
  MY_MONEY_WALLET_FEATURE_KEY,
  SellerPayoutTransactions,
} from '../../models/wallet.model';
import { v4 as uuidv4 } from 'uuid';
import * as generalSelector from './wallet.selectors';
import { pushNotification, started } from '../application/task.actions';
import { baseActions } from './wallet.actions';
import { IShoppingAvailablePaymentMethod } from '../../models/shopping.model';
import { IBuyer } from '../../models/buyer.model';

export interface IMyMoneyWalletPartialState {
  readonly [MY_MONEY_WALLET_FEATURE_KEY]: IMyMoneyWalletState;
}

@Injectable({ providedIn: 'root' })
export class WalletFacade {
  getPaymentCarousel$ = this.store.pipe(select(generalSelector.getPaymentCarousel));
  getWallets$ = this.store.pipe(select(generalSelector.getWallets));
  getWallet$ = this.store.pipe(select(generalSelector.getWallet));
  getMainWallet$ = this.store.pipe(select(generalSelector.getMainWallet));
  getPaymentMethod$ = this.store.pipe(select(generalSelector.getPaymentMethod));
  getExternalAccounts$ = this.store.pipe(select(generalSelector.getExternalAccounts));
  getActivePaymentMethods$ = this.store.pipe(select(generalSelector.getActivePaymentMethods));
  getCardTopUp$ = this.store.pipe(select(generalSelector.getCardTopUp));
  getLoyaltyPoints$ = this.store.pipe(select(generalSelector.getLoyaltyPoints));
  getPartyAccounts$ = this.store.pipe(select(generalSelector.getPartyAccounts));
  getNedbankBanks$ = this.store.pipe(select(generalSelector.getNedbankBanks));
  getDebitAccount$ = this.store.pipe(select(generalSelector.getDebitAccount));
  /** Active buyer can be empty. */
  getActiveBuyer$ = this.store.pipe(select(generalSelector.getActiveBuyer));
  getDashboardWidget$ = this.store.pipe(select(generalSelector.getDashboardWidget));
  getSellerPayoutTransactions$ = this.store.pipe(select(generalSelector.getSellerPayoutTransactions));
  getSellerPayoutTransactionItem$ = this.store.pipe(select(generalSelector.getSellerPayoutTransactionItem));
  getTaxInvoices$ = this.store.pipe(select(generalSelector.getTaxInvoices));
  getSellerStatements$ = this.store.pipe(select(generalSelector.getSellerStatements));

  constructor(private store: Store<IMyMoneyWalletPartialState>) {}

  fetchWallets = (onSucceed?: (data: IWallet[]) => void, onError?: (error: any) => void, silently?: boolean): void => {
    const taskId = `fetch-wallets-${uuidv4()}`;
    this.store.dispatch(
      started(new baseActions.MyMoneyWalletsRequestAction(taskId, onSucceed, onError), 'Fetching wallets', silently),
    );
  };

  fetchPaymentCarousel = (): void => {
    const taskId = `fetch-payment-carousel-${uuidv4()}`;
    this.store.dispatch(
      started(new baseActions.FetchPaymentCarouselRequestAction(taskId), 'Fetching payment carousel'),
    );
  };

  fetchActivePaymentMethods = (): void => {
    const taskId = `fetch-active-payment-methods-${uuidv4()}`;
    this.store.dispatch(
      started(new baseActions.FetchPaymentMethodActiveRequestAction(taskId), 'Fetching active payment method'),
    );
  };

  deletePaymentMethod = (paymentMethodId: string, onSucceed: () => void): void => {
    const taskId = `delete-payment-method-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.DeletePaymentMethodRequestAction(taskId, paymentMethodId, onSucceed),
        'Deleting payment method',
      ),
    );
  };

  editPaymentMethod = (
    paymentMethodId: string,
    request: IEditPaymentMethodRequest,
    onSucceed: (data?: any) => void,
  ): void => {
    const taskId = `edit-payment-method-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.editPaymentMethodRequestAction(taskId, paymentMethodId, request, onSucceed),
        'Edit payment method',
      ),
    );
  };

  editPaymentMethodName = (
    paymentMethodId: string,
    request: IEditPaymentMethodRequest,
    onSucceed: (data?: any) => void,
  ): void => {
    const taskId = `edit-payment-method-name-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.editPaymentMethodNameRequestAction(taskId, paymentMethodId, request, onSucceed),
        'Edit payment method name',
      ),
    );
  };

  editPaymentMethodDefault = (
    paymentMethodId: string,
    request: IEditPaymentMethodRequest,
    onSucceed: (data?: any) => void,
  ): void => {
    const taskId = `edit-payment-method-default-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.editPaymentMethodDefaultRequestAction(taskId, paymentMethodId, request, onSucceed),
        'Edit payment method default',
      ),
    );
  };

  fetchAddCard = (onSucceed: (response: IAddCardResponse) => void, silently?: boolean): void => {
    const taskId = `fetch-add-payment-card-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.FetchAddPaymentCardRequestAction(taskId, onSucceed),
        'Fetching add payment card',
        silently,
      ),
    );
  };

  fetchPaymentMethodId = (paymentMethodId: string, onSucceed: (data?: Ibalance) => void, silently?: boolean): void => {
    const taskId = `fetch-payment-method-balance-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.FetchPaymentMethodBalanceRequestAction(taskId, paymentMethodId, onSucceed),
        'Fetch payment method balance',
        silently,
      ),
    );
  };

  processAddCard = (paymentFlowId: string, onSucceed: (data?: IAddCardResponse) => void, onError: () => void): void => {
    const taskId = `process-add-card-${uuidv4}`;
    this.store.dispatch(
      started(new baseActions.ProcessAddCardRequestAction(paymentFlowId, taskId, onSucceed), 'Process add card'),
    );
  };

  selectMainWallet = (): void => {
    this.store.dispatch(new baseActions.MyMoneySelectMainWalletAction());
  };

  copyTextNotification = (text: string): void => {
    this.store.dispatch(
      pushNotification(new baseActions.MyMoneyCopyLinkSucceedAction(), `${text} has been copied to clipboard!`),
    );
  };

  addCard = (
    walletId: string,
    addCardRequest: IWalletTopUpAddCardRequest,
    onSucceed: (data: IWalletAddCardResponse) => void,
  ): void => {
    const taskId = `addCard-${walletId}-${JSON.stringify(addCardRequest)}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyAddCardRequestAction(taskId, walletId, addCardRequest, onSucceed),
        'Please wait a moment',
      ),
    );
  };

  fetchExternalAccounts = (
    walletId: string,
    destination: string,
    onSucceed?: (response: IWalletExternalAccount[]) => void,
    onError?: (error: any) => void,
    silently?: boolean,
  ): void => {
    const taskId = `fetchExternalAccounts-${walletId}-${destination}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyExternalAccountsRequestAction(taskId, walletId, destination, onSucceed, onError),
        'Fetching external accounts',
        silently,
      ),
    );
  };

  fetchBanks = (): void => {
    const taskId = `fetchBanks`;
    this.store.dispatch(started(new baseActions.MyMoneyBanksRequestAction(taskId), 'Fetching bank details'));
  };

  removeExternalAccount = (walletId: string, externalAccountId: string, onSucceed: () => void): void => {
    const taskId = `removeExternalAccount-${externalAccountId}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyRemoveExternalAccountRequestAction(taskId, walletId, externalAccountId, onSucceed),
        'Please wait a moment',
      ),
    );
  };

  fetchCardTopup = (
    walletId: string,
    destination: string,
    onSucceed?: (response: IWalletTopUpCardResponse[]) => void,
    silently?: boolean,
  ): void => {
    const taskId = `fetchCardTopup-${walletId}-${destination}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyCardWithDestinationRequestAction(taskId, walletId, destination, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  deleteCardTopup = (walletId: string, cardId: string, destination: string, onSucceed: () => void): void => {
    const taskId = `deleteCardTopup-${walletId}-${cardId}-${destination}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyDeleteCardRequestAction(taskId, walletId, cardId, destination, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };

  payTopUp = (
    walletId: string,
    cardId: string,
    pay: IWalletTopUpPayRequest,
    onSucceed: (data: IWalletAddCardResponse) => void,
  ): void => {
    const taskId = `payTopUp-${walletId}-${cardId}-${JSON.stringify(pay)}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayTopUpRequestAction(taskId, walletId, cardId, pay, onSucceed),
        'Please wait a moment',
      ),
    );
  };

  fetchPartyAccounts = (onSucceed: (data: IWalletPartyAccount[]) => void): void => {
    const taskId = `fetch-party-accounts`;
    this.store.dispatch(
      started(new baseActions.MyMoneyPartyAccountsRequestAction(taskId, onSucceed), 'Please wait a moment'),
    );
  };

  fetchLoyaltyPoints = (silently?: boolean): void => {
    const taskId = `fetch-loyalty-points-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyLoyaltyPointsRequestAction(taskId),
        'Fetching Loyalty Points. Please wait a moment.',
        silently,
      ),
    );
  };

  payCheck = (
    walletId: string,
    payload: IWalletCheckPayRequest,
    onSucceed: (data: any) => void,
    silently = true,
  ): void => {
    const taskId = `pay-check-${walletId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyTopupPayCheckRequestAction(taskId, walletId, payload, onSucceed),
        'Checking payment',
        silently,
      ),
    );
  };

  topupAccount = (
    walletId: string,
    payload: IWalletTopUpAccountRequest,
    onSucceed: (data: IWalletTopUpAccountResponse) => void,
  ): void => {
    const taskId = `topup-account-${walletId}-${JSON.stringify(payload)}`;
    this.store.dispatch(
      started(new baseActions.MyMoneyTopupAccountRequestAction(taskId, walletId, payload, onSucceed)),
    );
  };

  topupAccountStatus = (
    walletId: string,
    topupId: string,
    onSucceed: (data: IWalletTopUpStatusResponse) => void,
    loadLabel: string,
    silently?: boolean,
  ): void => {
    const taskId = `topup-account-status-${walletId}-${topupId}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyTopupAccountStatusRequestAction(taskId, walletId, topupId, onSucceed),
        loadLabel,
        silently,
      ),
    );
  };

  topupCardStatus = (
    walletId: string,
    cardId: string,
    topupId: string,
    onSucceed: (data: IWalletTopUpStatusResponse) => void,
    loadLabel: string,
    silently?: boolean,
  ): void => {
    const taskId = `topup-card-status-${walletId}-${cardId}-${topupId}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyTopupCardStatusRequestAction(taskId, walletId, cardId, topupId, onSucceed),
        loadLabel,
        silently,
      ),
    );
  };

  payByCard = (
    flowId: string,
    request: IPayByCardRequest,
    onSucceed?: (response: IPayByCardResponse) => void,
    silently = false,
  ): void => {
    const taskId = `pay-by-card-${flowId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayByCardRequestAction(taskId, flowId, request, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  payByPointsOnly = (flowId: string, onSucceed: () => void, silently = false): void => {
    const taskId = `pay-by-points-only-${flowId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayByPointsOnlyRequestAction(taskId, flowId, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  payByWallet = (
    flowId: string,
    request: IPayByWalletRequest,
    onSucceed?: (response: IWalletPayResponse) => void,
    silently = false,
  ): void => {
    const taskId = `pay-by-wallet-${flowId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayByWalletRequestAction(taskId, flowId, request, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  payForFree = (flowId: string, onSucceed: () => void, silently = false): void => {
    const taskId = `pay-for-free-${flowId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayForFreeRequestAction(taskId, flowId, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  topUpAndPay = (
    flowId: string,
    request: ITopUpAndPayRequest,
    onSucceed?: (response: ITopUpAndPayResponse) => void,
    silently = false,
  ): void => {
    const taskId = `pay-by-wallet-${flowId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyTopUpAndPayRequestAction(taskId, flowId, request, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  clearPaymentInfo = (): void => {
    const taskId = `clear-payment-info-${uuidv4()}`;
    this.store.dispatch(new baseActions.MyMoneyClearPayInfoAction(taskId));
  };

  /**
   * Set active buyer for whole application.
   *
   * @access This method should be called only from {@link BusinessEntityService}
   * @param buyer New active buyer
   */
  setActiveBuyer = (buyer: IBuyer): void => {
    const taskId = `set-active-buyer-${JSON.stringify(buyer)}-${uuidv4()}`;
    this.store.dispatch(new baseActions.MyMoneySetActiveBuyerAction(taskId, buyer));
  };

  filterPayoutTransactions = (
    storeId: string,
    filter: IFilterPayoutTransactionRequest,
    pageSize = 20,
    pageNumber = 0,
    onSucceed?: (response: SellerPayoutTransactions) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `filter-payout-transactions-${JSON.stringify(filter)}`;
    this.store.dispatch(
      started(
        new baseActions.FilterPayoutTransactionsRequestAction(
          taskId,
          storeId,
          filter,
          pageSize,
          pageNumber,
          onSucceed,
          onError,
        ),
        'Please wait a moment',
      ),
    );
  };

  filterSettlementsExport = (
    filter: IFilterSettlementRequest,
    storeId: string,
    onSucceed?: (response: ISettlementExport) => void,
  ): void => {
    const taskId = `filter-settlements-export-${JSON.stringify(filter)}`;
    this.store.dispatch(
      started(
        new baseActions.FilterSettlementsExportRequestAction(taskId, filter, storeId, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };

  filterTransactionsExport = (
    filter: IFilterPayoutTransactionRequest,
    storeId: string,
    onSucceed?: (response: ISettlementExport) => void,
  ): void => {
    const taskId = `filter-transactions-export-${JSON.stringify(filter)}`;
    this.store.dispatch(
      started(
        new baseActions.FilterTransactionsExportRequestAction(taskId, filter, storeId, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };

  fetchTaskFile = (fetchTaskId: string, onSucceed?: (response: string) => void): void => {
    const taskId = `fetch-task-file-${JSON.stringify(fetchTaskId)}`;
    this.store.dispatch(
      started(new baseActions.FetchTaskFileRequestAction(taskId, fetchTaskId, onSucceed), 'Please wait a moment', true),
    );
  };

  fetchTaskDetail = (fetchTaskId: string, onSucceed?: (response: ITaskDetail) => void): void => {
    const taskId = `fetch-task-detail-${JSON.stringify(fetchTaskId)}`;
    this.store.dispatch(
      started(
        new baseActions.FetchTaskDetailRequestAction(taskId, fetchTaskId, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };

  fetchTaxInvoices = (
    filter: ITaxInvoiceRequest,
    storeId: string,
    pageSize = 20,
    pageNumber = 1,
    onSucceed?: (response: ITaxInvoice) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `fetch-tax-invoices-${JSON.stringify(filter)}`;
    this.store.dispatch(
      started(
        new baseActions.FetchTaxInvoicesRequestAction(
          taskId,
          filter,
          storeId,
          pageSize,
          pageNumber,
          onSucceed,
          onError,
        ),
        'Please wait a moment',
      ),
    );
  };

  downloadTaxInvoice = (taxInvoiceId: string, storeId: string, onSucceed?: (response: any) => void): void => {
    const taskId = `download-tax-invoice-${JSON.stringify(taxInvoiceId)}`;
    this.store.dispatch(
      started(
        new baseActions.DownloadTaxInvoiceRequestAction(taskId, taxInvoiceId, storeId, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };

  fetchSellerStatements = (
    request: ISellerStatementRequest,
    storeId: string,
    pageSize = 20,
    page = 1,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `fetch-seller-statements-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.FetchSellerStatementRequest(taskId, request, storeId, pageSize, page, onError),
        'Fetching Your data. Please wait a moment',
        false,
      ),
    );
  };

  downloadSellerStatement = (statementNumber: string, storeId: string, onSucceed?: (response: any) => void): void => {
    const taskId = `download-seller-statement-${JSON.stringify(statementNumber)}`;
    this.store.dispatch(
      started(
        new baseActions.DownloadSellerStatementRequestAction(taskId, statementNumber, storeId, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };

  generateSellerStatement = (
    filter: ISellerStatementExportRequest,
    storeId: string,
    onSucceed?: (response: ISellerStatementExport) => void,
  ): void => {
    const taskId = `generate-seller-statement-export-${JSON.stringify(filter)}`;
    this.store.dispatch(
      started(
        new baseActions.GenerateSellerStatementRequest(taskId, filter, storeId, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };

  generatePaymentRemittance = (
    storeId: string,
    filter: IGenerateRemittanceRequest,
    onSucceed?: (response: IPaymentRemittanceExport) => void,
  ): void => {
    const taskId = `generate-payment-remittance-export-${JSON.stringify(filter)}`;
    this.store.dispatch(
      started(
        new baseActions.GeneratePaymentRemittanceRequestAction(taskId, filter, storeId, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };

  fetchNedbankBanks = (): void => {
    const taskId = `fetchNedbankBanks`;
    this.store.dispatch(
      started(new baseActions.MyMoneyNedbankBanksRequestAction(taskId), 'Fetching Nedbank banks', true),
    );
  };

  payByEft = (flowId: string, onSucceed: () => void, silently = false): void => {
    const taskId = `pay-by-eft-${flowId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayByEftRequestAction(taskId, flowId, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  payByAmex = (
    flowId: string,
    request: IPayByCardRequest,
    onSucceed: (response: IPayByCardResponse) => void,
    silently = false,
  ): void => {
    const taskId = `pay-by-amex-${flowId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayByAmexRequestAction(taskId, flowId, request, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  payByCredit = (
    flowId: string,
    request: IPayByCreditRequest,
    onSucceed: (response: IPayByCreditResponse) => void,
    silently = false,
  ): void => {
    const taskId = `pay-by-credit-${flowId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayByCreditRequestAction(taskId, flowId, request, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  setPaymentMethod = (paymentMethod: IShoppingAvailablePaymentMethod): void => {
    const taskId = `set-payment-method-${uuidv4()}`;
    this.store.dispatch(new baseActions.MyMoneySetPaymentMethodAction(taskId, paymentMethod));
  };
}
