import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-date-picker',
  templateUrl: './date-picker.component.html',
})
export class DatePickerComponent {
  @Input() title: string;
  @Input() dateFrom: string;
  @Input() dateTo: string;
  @Output() dateChange = new EventEmitter();

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    const createdFrom = new Date(dateRangeStart.value);
    const createdTo = new Date(dateRangeEnd.value);

    this.dateChange.emit({ createdFrom: createdFrom.toISOString(), createdTo: createdTo.toISOString() });
  }
}
